import { formatPrice } from '@/functions/formatNumber'

export default (product) => {
  const vatCoeff = (1 + (product.vat / 100))
  if (!product.price && product.price_excl) {
    product.price = formatPrice(product.price_excl * vatCoeff)
  }
  if (!product.price_excl && product.price) {
    product.price_excl = formatPrice(product.price / vatCoeff)
  }
  return product
}
