<template>
  <div class="gap-4 max-w-6xl xl:max-w-full mx-auto">
    <div class="card shadow flex flex-col gap-4 items-start">
      <div v-if="nieuw">
        <UIInputCheckbox label="Volgende nummering in volgorde gebruiken" @update:modelValue="form.doc_number = $event ? null : ''" :modelValue="form.doc_number === null" />
        <UIInput v-if="form.doc_number !== null" required v-model="form.doc_number" label="# Nummer" class="w-full max-w-2xl" placeholder="# Nummer ..." />
      </div>

      <div class="card flex flex-col" v-if="searchItem">
        <b>Klant</b>
        <span>{{ searchItem.name }}</span>
        <span>{{ searchItem.second_name }}</span>
        <span>{{ searchItem.BTW }}</span>
        <span>{{ searchItem.adres }}</span>
        <small class="text-right text-gray-400" @click="focusGlobalSearch">Wijzigen kan via zoekbalk</small>
      </div>
      <UIInput v-else @click="focusGlobalSearch" required disabled v-model="form.facturatie_id" label="Klant" class="w-full max-w-2xl" placeholder="Bedrijf zoeken met zoekbalk ..." />

      <UIInputDatePicker required label="Datum" class="text-left" v-model:date="form.date" />
      <UIInputDatePicker required label="Vervaldatum" class="text-left" v-model:date="form.expiration_date" />

      <div class="flex flex-col gap-4 w-full">
        <div class="border p-2 w-full">
          <Ritten v-model="form.ritten" />
        </div>
        <div class="border p-2 w-full">
          <Ritten
            label="Klant referenties"
            placeholder="Referentie"
            label-id="Klant referentie"
            input-type="text"
            property="facturatie_referentie"
            v-model="form.facturatie_referenties"
          />
        </div>
        <UIInputCheckbox class="my-4" label="Verberg adressen op factuur (ritten)?" :disabled="!form.ritten?.length" v-model="form.verberg_adressen" />
        <div class="border p-2 w-full">
          <Producten v-model="form.products" />
        </div>
      </div>

      <UIInput textarea v-model="form.notitie" label="Notitie" class="w-full max-w-3xl" rows="4" placeholder="Notitie ..." />

      <button type="submit" :disabled="!((form.doc_number === null || form.doc_number > 0) && form.date && form.expiration_date)" class="btn success" @click.prevent.stop="onSubmit()">Opslaan</button>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import clone from 'just-clone'

import UIInputDatePicker from '@/components/UI/Input/DatePicker.vue'
import UIInput from '@/components/UI/Input/Input.vue'
import UIInputCheckbox from '@/components/UI/Input/Checkbox'
import Producten from './Producten.vue'
import Ritten from './Ritten.vue'

import { dateString } from '@/functions/formatDate'
import useSearchItem from '@/hooks/useSearchItem'
import { focusGlobalSearch } from '@/hooks/useSearchGlobal'
import mapProductPrices from './mapProductPrices.js'

const searchItem = useSearchItem()

const props = defineProps({
  nieuw: Boolean,
  disabled: Boolean,
  modelValue: Object,
})

const emit = defineEmits(['update:modelValue', 'submit'])

const now = Date.now()
const generateForm = (modelValue) => {
  const products = modelValue?.products?.length ? modelValue?.products : []
  const obj = {
    doc_number: modelValue?.doc_number || null,
    client_id: modelValue?.client_id || null,
    date: dateString(modelValue?.date || now),
    expiration_date: dateString(modelValue?.expiration_date || now + 1209600000),
    products: products.filter(el => !(el.rit_id > 0)).map(mapProductPrices),
    ritten: products.filter(el => el.rit_id > 0),
    notitie: modelValue?.notitie || '',
    verberg_adressen: modelValue?.verberg_adressen || false,
  }
  return obj
}

const form = ref(generateForm(props.modelValue))
watch(form, (val) => emit('update:modelValue', val))
watch(() => props.modelValue, (val) => {
  if (val && JSON.stringify(val) !== JSON.stringify(form.value)) {
    form.value = generateForm(val)
  }
})
onMounted(() => emit('update:modelValue', form.value))

const onSubmit = () => {
  const submitData = clone(form.value)
  submitData.client_id = searchItem.value?.id
  emit('submit', submitData)
}
</script>
